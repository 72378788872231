<template>
  <div class="container">
    <a-empty v-if="blogs.length < 1"><span>没有收藏哦</span></a-empty>
    <a-list v-if="blogs.length > 0">
      <a-list-item v-for="item in blogs" :key="item.id" class="eachItem">
        <img :src="item.cover" alt="" height="130px" width="200px" @click="goDetail(item)" 
        v-if="item.cover !== undefined && item.cover !== null">
        <div class="textSide">
          <h2 class="title" @click="goDetail(item)">{{item.title}}</h2>
          <span style="float: left;"><a-tag color="#7B8184">
            标签:</a-tag><a-tag v-for="(item,index) in item.tags" :key="index">{{item.name}}</a-tag>
          </span>
          <br />
          <a-descriptions class="desc">
            <a-descriptions-item @click="goDetail(item)">
              {{item.description}}
            </a-descriptions-item>
          </a-descriptions>
          <div class="count">
            <span class="countItem"><user-outlined />{{item.author}}</span>
            <span class="countItem"><heart-filled />{{item.likes}}</span>
            <span class="countItem"><message-outlined />{{item.cmtNum}}</span>
            <span class="countItem"><calendar-outlined />{{item.pubTime}}</span>
            <span class="countItem"><eye-outlined />{{item.viewNum}}</span>
          </div>
        </div>
        <div class="operation">
          <a-dropdown class="optItem" >
            <small-dash-outlined  style="font-size: 26px;" />
            <template #overlay >
              <a-menu style="margin-top: -18px;">
                <a-menu-item>
                  <alert-outlined style="margin-right: 8px;" />
                  <span @click="delBlog(item)">删除文章</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
  import * as BLOG from '../../api/blog.js';
  import { UserOutlined, HeartFilled, MessageOutlined, CalendarOutlined, EyeOutlined, StarOutlined, AlertOutlined, SmallDashOutlined } from '@ant-design/icons-vue';
  export default {
    name: 'MyStarsBlog',
    components: {
      UserOutlined,
      HeartFilled,
      MessageOutlined,
      CalendarOutlined,
      EyeOutlined,
      StarOutlined,
      AlertOutlined, 
      SmallDashOutlined
    },
    data() {
      return {
        blogs: [],
        pagination: {
          current: 1,
          size: 10,
          total: 0,
        }
      }
    },
    mounted() {
      this.loadStarsBlog()
    },
    methods: {
      loadStarsBlog() {
        let params = new FormData()
        params.append('page', this.pagination.current)
        params.append('size', this.pagination.size)
        BLOG.srhUserStarsBlog(params).then(res=>{
          // console.log('stars', res)
          if(res.data.code == 200) {
            this.blogs = res.data.data.records;
            this.pagination.current = res.data.data.current;
            this.pagination.total = res.data.data.total;
            this.pagination.size = res.data.data.size;
          }
        })
      },
      goDetail(item) {
        this.$router.push({
          path: '/blog',
          query: {
            id: item.id
          }
        })
      },
      delBlog(item) {

      }
    },
  }
</script>
<style scoped>
  .container {
    width: 640px;
    height: 100%;
  }
  .eachItem {
    width: 650px;
    height: 150px;
    margin-top: 20px;
  }
  .textSide {
    width: 450px;
    margin-left: 16px;
  }
  .title {
    text-align: left;
    font-size: 18px;
    line-height: 27px;
    height: 27px;
    font-family: -apple-system,SF UI Display,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif;
  }
  .desc {
    margin-top: 6px;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 13px;
    color: #999;
    line-height: 24px;
    max-height: 72px;
  }
  .count {
    text-align: left;
  }
  .countItem:not(:first-child) {
    margin-left: 12px;
  }
  .operation {
    width: 20px;
    height: 150px;
    margin-top: 1px;
  }
</style>